<template>
  <article :class="['inspire-card cart-type-aquarist rounded-xxl w-full sm:w-74 h-full bg-light overflow-hidden transition-all duration-200 hover:shadow transform hover:-translate-y-0.5 border border-card-border hover:border-card-border-hover', className, `inspire-card-${variant.id}`]">
    <div class="relative flex items-center justify-center cursor-pointer w-auto h-50" :aria-label="$t('text-product-image')">
      <span class="sr-only">{{ $t('text-product-image') }}</span>
      <a
        :href="translatePath(ROUTES.CODE.INSPIRATION, slugify(variant?.id, variant?.name))"
        class="block overflow-hidden absolute top-0 left-0 bottom-0 right-0 box-border m-0 text-light bg-radial-gradient"
        :aria-label="variant.name"
        @click.prevent="handleContentQuickView"
      >
        <Image
          :src="variant?.image?.url || _.get(variant, 'images.0.url', null) || siteSettings.product.placeholderImage[config.public.layoutDesign]"
          :alt="variant.name"
          layout="fill"
          object-fit="cover"
          :width="294"
          :height="200"
          sizes="294px"
          class-name="inspire-image"
          :loading="loading"
        />
      </a>
      <div :class="[ 'absolute top-0 pt-2.5 px-5 w-full', variant?.supplier ? 'flex justify-between space-x-5' : 'flex justify-end' ]">
        <Link
          v-if="variant?.supplier"
          :href="translatePath(ROUTES.CODE.SUPPLIER, slugify(variant?.supplier?.id, variant?.supplier?.name))"
          :aria-label="variant?.supplier?.name"
          class="flex flex-row space-x-1 items-center overflow-hidden cursor-pointer group"
        >
          <Avatar :src="variant?.supplier?.logo" size="small" :name="variant?.supplier?.name" :loading="loading" />
          <div class="flex flex-col w-full text-light font-bold text-xs group-hover:underline">
            <div>{{ supplierName?.first }}</div>
            <div>{{ supplierName?.last }}</div>
          </div>
        </Link>
        <ContentFavorite v-if="_.has(variant, 'isInCustomerFavorites')" :variant="variant" :accent="true" class="absolute top-2.5 end-5" />
      </div>
      <div v-if="_.has(variant, 'isInCustomerLikes')" class="absolute bottom-4 start-5 flex flex-row items-center justify-start space-x-2.5">
        <ContentViews :variant="variant" :accent="true" />
        <ContentLikes :variant="variant" :accent="true" />
        <MessagesBadge :variant="variant" :accent="true" :on-click="openDetailsWithComments" />
      </div>
    </div>
    <header class="flex flex-col justify-between px-5 pt-4 pb-5">
      <a
        :href="translatePath(ROUTES.CODE.INSPIRATION, slugify(variant?.id, variant?.name))"
        :aria-label="variant.name"
        @click.prevent="handleContentQuickView"
      >
        <div class="line-clamp-2 leading-tight text-lg text-primary-dark font-bold overflow-ellipsis overflow-hidden cursor-pointer mb-1 hover:primary-dark-hover hover:underline focus:primary-dark-hover focus:underline" :title="variant.name" v-text="variant.name" />
      </a>
      <div class="w-full mb-2 text-xs text-primary-dark font-normal line-clamp-1">
        <span v-if="variant?.publishedAt">{{ $luxon.fromISO(variant?.publishedAt).setLocale(routeLang.code.value).toRelative() }}</span>
      </div>
      <div class="w-full flex justify-end">
        <EditContentBtn v-if="_.get(variant, 'supplier.id') === auth?.idSupplier" :data="variant" variant="simple" />
      </div>
    </header>
  </article>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { STATUS } from "@utils/constants";
import { siteSettings } from "@settings/site.settings";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';
import Image from "@components/ui/image";
import EditContentBtn from "@components/content/content-edit/edit-content-btn";
import ContentFavorite from "@components/content/content-details/content-favorite";
import ContentViews from "@components/content/content-details/content-views";
import ContentLikes from "@components/content/content-details/content-likes";
import MessagesBadge from "@components/common/messages-badge";
import Avatar from "@components/ui/avatar";
import Link from "@components/ui/link/link";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  className: {
    type: String,
    required: false,
    default: () => ''
  },
  loading: {
    type: String,
    default: null
  }
})

const config = useRuntimeConfig();
const auth = useAuthStore();
const store = useSelectedStore();
const ui = useUIStore();
const { $eventBus, $luxon } = useNuxtApp();
const routeLang = useRouteLang();

const supplierName = computed(() => {
  const str = props.variant?.supplier?.name || '';
  const middleIndex = Math.floor(str.length / 2);
  let leftIndex = str.lastIndexOf(' ', middleIndex);
  let rightIndex = str.indexOf(' ', middleIndex + 1);
  if (rightIndex === -1) {
    rightIndex = str.length;
  }
  if (leftIndex === -1) {
    leftIndex = 0;
  }
  const useLeft = (middleIndex - leftIndex) < (rightIndex - middleIndex);
  const splitIndex = useLeft ? leftIndex : rightIndex;
  const first = _.trim(_.slice(str, 0, splitIndex).join(''));
  const last = _.trim(_.slice(str, splitIndex + 1).join(''));
  return { first, last };
});


const handleContentQuickView = async (action = null) => {
  if (ui.displayModal) {
    $eventBus.emit('modal:close');
  }
  nextTick(async () => {
    if (_.get(props.variant, 'status', STATUS.DRAFT) !== STATUS.ACTIVE) {
      await navigatorTo(translatePath(ROUTES.CODE.INSPIRATION_EDIT, props.variant?.id));
    } else {
      store.setAction(action);
      store.setContent(props.variant);
      $eventBus.emit('modal:open', 'CONTENT_DETAILS');
    }
  });
};

const openDetailsWithComments = () => handleContentQuickView('SELECT_TAB_COMMENTS');

</script>
